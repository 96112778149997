import React, { useState, useEffect } from "react";
import Form from '../components/form';
const CustomButton = ({ onClick, buttonString }) => (
  <button className="div-home-17"  onClick={onClick} >
  <a className="div-home-20" 
  >
    CONTACT US
  </a>
</button>

);
export default function Home(props) {
  const [ramadanClosed,setRamadanClosed ] = useState(true);
  const [shredClosed, setShredClosed] = useState(true);
  const [bodyAnalysisClosed, setBodyAnalysisClosed]  = useState(true);
  const [gymClosed, setGymClosed]  = useState(true); 
  const [circuitClosed, setCircuitClosed] = useState(true);
  const [gwlpClosed, setGwlpClosed] = useState(true);
  const [conceptHerClosed, setConceptHerClosed] = useState(true);
  const [revivalClosed, setRevivalClosed] = useState(true);
  const [pregnantClosed, setPregnantClosed] = useState(true);
  const [climbingClosed, setClimbingClosed] = useState(true);
  const [hikingClosed, setHikingClosed] = useState(true);
  climbingClosed
	useEffect(async () => {
		const fetchData = async () => {
			try {
				const apiUrl1 = `https://www.aos.fit/api/getalldata`;
				let allData = await fetch(apiUrl1);
				const allDataResult = await allData.json();
				setRamadanClosed(allDataResult["ramadanClosed"]);
				setShredClosed(allDataResult["shredClosed"]);
				setBodyAnalysisClosed(allDataResult["bodyAnalysisClosed"]);
				setGymClosed(allDataResult["gymClosed"]);
				setCircuitClosed(allDataResult["circuitClosed"]);
				setGwlpClosed(allDataResult["gwlpClosed"]);
				setConceptHerClosed(allDataResult["conceptHerClosed"]);
				setRevivalClosed(allDataResult["revivalClosed"]);
				setPregnantClosed(allDataResult["pregnantClosed"]);
        setClimbingClosed(allDataResult["climbingClosed"]);
        setHikingClosed(allDataResult["hikingClosed"])
        
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		await fetchData();
	}, []);
	return (
		<>
			<div className="div-home-2">
				<div className="div-home-3">
					<div className="div-home-4">
						<img loading="lazy" src="/logo.png" className="img" />
						<div className="div-home-5">ART OF SPORTS</div>
					</div>
				</div>
				<div className="div-home-8">
					<hr
						style={{
							border: "none",
							borderTop: " 1px solid rgb(0, 0, 238)",
							width: "70%",
							margin: "30px 0 20px 0px"
						}}
					/>
					<div className="div-home-7">SERVICES</div>

					{!bodyAnalysisClosed && (
						<a href="/free-360-body-analysis" className="div-home-9">
							FREE 360 Body Analysis
						</a>
					)}


          {!climbingClosed && (
						<a href="/rockClimbing" className="div-home-9">
							{" "}
							Rock Climbing
						</a>
					)}

        {!hikingClosed && (
						<a href="/hiking" className="div-home-9">
							{" "}
							Hiking
						</a>
					)}


					{!ramadanClosed && (
						<a href="/Ramadan" className="div-home-9">
							{" "}
							RAMADAN
						</a>
					)}

					{!gymClosed && (
						<a href="/gym1" className="div-home-9">
							THE GYM{" "}
						</a>
					)}

					{!shredClosed && (
						<a href="/shred-30" className="div-home-9">
							{" "}
							SHRED 30
						</a>
					)}

					{!circuitClosed && (
						<a href="/circuit-gym1" className="div-home-9">
							{" "}
							CIRCUIT
						</a>
					)}

					{!gwlpClosed && (
						<a href="/cgwlp30" className="div-home-9">
							{" "}
							GWLP 30
						</a>
					)}

					{!conceptHerClosed && (
						<a href="/concept-her1" className="div-home-9">
							{" "}
							CONCEPT HER
						</a>
					)}

					{!revivalClosed && (
						<a href="/revival" className="div-home-9">
							{" "}
							REVIVAL
						</a>
					)}

					{!pregnantClosed && (
						<a href="/pregnant-mama" className="div-home-9">
							{" "}
							PRE AND POST-NATAL
						</a>
					)}

					<hr
						style={{
							border: "none",
							borderTop: " 1px solid rgb(0, 0, 238)",
							width: "70%",
							margin: "30px 0 0 0"
						}}
					/>
					<Form
						type="Home"
						buttonComponent={(toggleModal) => (
							<CustomButton
								buttonString="BOOK your 35 % OFFER"
								onClick={toggleModal}
							/>
						)}
					/>
				</div>
			</div>
			<style jsx>{`
        .div-home-2 {
          align-items: center;
          background-color: rgb(0,0,0);
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-home-2 {
            max-width: 100%;
            padding: 0 0px;
          }
        }
        .div-home-3 {
          align-items: center;
          background-color: rgb(0,0,0);
          display: flex;
          width: 1170px;
          max-width: 100%;
          flex-direction: column;
          padding: 0px;
        }
        .div-home-4 {
          display: flex;
          width: 825px;
          max-width: 100%;
          flex-direction: column;
          align-items: center ;
        }
        .img {
          aspect-ratio: 2.63;
          object-fit: contain;
          object-position: center;
          width: 100px;
          max-width: 100%;
          overflow: hidden;
          align-self: center;
        }
        .div-home-5 {
          color: rgb(255,255,255);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 500 12px/15px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-home-5 {
            white-space: initial;
          }
        }
        .div-home-6 {
          border: 0px solid var(--www-aos-fit-blue, #00e);
          align-self: stretch;
          display: flex;
          margin-top: 32px;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-home-6 {
            max-width: 100%;
          }
        }
        .div-home-7 {
          color: var(--www-aos-fit-scorpion, #565656);
          text-align: center;
          letter-spacing: 2px;
          align-self: center;
          margin-bottom: 13px;
          white-space: nowrap;
          font: 700 30px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-home-7 {
            white-space: initial;
          }
        }
        .div-home-8 {
          gap: 15px;
          justify-content: flex-end;
          align-items: center;
          display: flex;
          margin-top: 30px;
          width: 1170px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-home-8 {
            margin-top: 30px;
          }
        }
        /* styles.css */

        .div-home-9 {
          color: var(--www-aos-fit-nero, #fff);
          text-align: center;
          letter-spacing: 1px;
          white-space: nowrap;
          justify-content: center;
          border-radius: 3px;
          background-color: var(--www-aos-fit-black, #000);
          width: 366px;
          max-width: 100%;
          padding: 10px;
          font: 700 23px/34px Inter, -apple-system, Roboto, Helvetica, sans-serif;
          text-decoration: none;
        }
        
        .div-home-9:hover {
        //   text-decoration: underline; /* Add underline on hover if desired */
        background-color: rgb(31,31,31);
        }
        
        @media (max-width: 991px) {
          .div-home-9 {
            white-space: initial;
            margin-top: 30px;
            padding: 0 5px 0 6px;
          }
        }
        .div-home-17 {
        //   color: var(--www-aos-fit-nero, #fff);
          text-align: center;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border: 1px solid var(--www-aos-fit-black-20, rgba(0, 0, 0, 0.2));
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: var(--www-aos-fit-dark-blue, #00a);
          align-self: center;
          margin-top: 45px;
          margin-bottom: 30px;
          width: 188px;
          max-width: 100%;
          text-decoration: none;
          padding: 11px 20px;
          font: 600 18px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-home-9:hover {
            //   text-decoration: underline; /* Add underline on hover if desired */
            //   background-color: gray; /
        }
        .div-home-20 {
            text-decoration: none;
            color: var(--www-aos-fit-nero, #fff);
        }

        @media (max-width: 991px) {
          .div-home-17 {
            white-space: initial;
            margin-top: 40px;
            margin-buttom: 40px;
          }
        }
        }
      `}</style>
		</>
	);
}


