import React from 'react';
const RockClimbingChallenge = () => {
  return (
    <>
    <div className='div-crr'>
    <div className="rock-climbing-card2">
<br/>  <br/>  <br/>
<h2 className="rock-climbing-title">    
THIS PROGRAM IS PERFECT FOR<br />
ORDINARY PEOPLE LOOKING <br />
FOR A PURPOSE TO TRAIN AND LIVE FULLY
        </h2>
<p className="rock-climbing-subtitle1"></p>
        <p className="rock-climbing-description1">
        We know it’s hard to stay motivated when your workouts feel repetitive. That’s why this program adds excitement by training for a real adventure

        </p>

        <p className="rock-climbing-subtitle1">You Lack A Clear Purpose In Training</p>
        <p className="rock-climbing-description1">
        Feeling like your workouts don’t have a goal? With this program, each workout gets you closer to conquering a new challenge—rock climbing. 
        </p>


        <p className="rock-climbing-subtitle1">You’re Struggling To Find A Community</p>
        <p className="rock-climbing-description1">
        Meet like-minded individuals who are on the same journey, supporting, motivating each other, working towards a common goal.
        </p>

        <p className="rock-climbing-subtitle1">You Have A Fear Of Failure </p>
        <p className="rock-climbing-description1">
        You might feel uncertain about starting something new. Don’t worry—this program is designed for all fitness levels. We’ll guide you step-by-step.
        </p>


        <p className="rock-climbing-subtitle1">You’re Worried About Time Commitments</p>
        <p className="rock-climbing-description1">
        We understand your life is busy, which is why this program is designed to balance work, life, and training without feeling overwhelmed.
        </p>

        <p className="rock-climbing-subtitle1">You Want to Feel More Confident and Capable</p>
        <p className="rock-climbing-description1">
        Our workouts build strength and endurance that will give you the confidence to take on rock climbing and other adventures. You’ll be amazed at what your body can achieve.
        </p>

        <p className="rock-climbing-subtitle1">You Dream of Living a Life Full of Adventure</p>
        <p className="rock-climbing-description1">
        You don’t want to settle for the ordinary. This program is your chance to break free from routine and start living an adventurous, purpose-life.
        </p>

        <p className="rock-climbing-subtitle1">You Want to Discover New Passions</p>
        <p className="rock-climbing-description1">
        This program is more than just fitness—it’s about discovering new passions like rock climbing and other outdoor sports that can transform your lifestyle.
        </p>

      </div>

        </div>


      <style>{`
            .div-crr {
                  background-color: white;
      }
          .rock-climbing-card2 {
    width: 100%;
    max-width: 910px;
    margin: 0 auto;
  }

  .rock-climbing-title {
    color: rgb(255,69,0);
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Futura XBlk BT', sans-serif;
    margin: -15px 0 0 0;
  }

  .rock-climbing-description1 {
    text-align: center;
    width: 90%;
    font-family: 'Siro-Bold', sans-serif;
    color: #374151;
    font-size: 1.5rem;
    margin-bottom: 2rem;
        margin-left: auto;
    margin-right: auto;
  }

  .rock-climbing-subtitle1 {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .rock-climbing-section {
    display: flex;
    margin-bottom: 2rem;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }

  .rock-climbing-section.reverse {
    flex-direction: row-reverse;
  }

  .rock-climbing-section-image,
  .rock-climbing-section-image-placeholder {
    width: 50%;
    max-width: 400px;
    height: 250px;
    border-radius: 0.5rem;
  }


  .rock-climbing-section-content,
  .rock-climbing-section-contentL {
    width: 50%;
    max-width: 400px;
  }

  .rock-climbing-section-content {
    margin-left: 3rem;
  }

  .rock-climbing-section-contentL {
    margin-right: 3rem;
    text-align: left;
  }

  .rock-climbing-section-title {
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .rock-climbing-section-text {
    color: #black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
    text-align: left;
  }

  .rock-climbing-countdown {
    font-family: 'Siro-Bold', sans-serif;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .rock-climbing-countdown p {
    font-family: 'Calibri', sans-serif;
  }

  .rock-climbing-section-text {
    color: black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
  }

  .rock-climbing-timer {
    font-size: 2rem;
        text-align: center;
    color: rgb(255,69,0);
  }



  .rock-climbing-timer span {
    margin: 0 5px;
  }

  .hicking-cta-button {
    background-color: rgb(255,69,0);
    color: white;
    font-weight: bold;

    padding: 0.75rem 2rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .hicking-cta-button:hover {
    background-color: rgb(255,69,0);
  }

  @media (max-width: 768px) {
    .rock-climbing-section,
    .rock-climbing-section.reverse {
      flex-direction: column;
      align-items: center;
    }

      .rock-climbing-description1 {
    font-size: 1rem;
  }
      .rock-climbing-section {
    margin-bottom: 0rem;

  }

    .rock-climbing-section-image,
    .rock-climbing-section-image-placeholder,
    .rock-climbing-section-content,
    .rock-climbing-section-contentL {
      width: 90%;
      max-width: 350px;
      margin: 0 0 1rem 0;
    }
    .rock-climbing-section-contentL {
      text-align: center;
    }

    .rock-climbing-section-title {
      text-align: center;
    }
    .rock-climbing-title {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
        .rock-climbing-section-text {
    text-align: center;
  }

    .rock-climbing-subtitle1 {
    font-size: 1.25rem;
    }
    .hicking-cta-button {
    background-color: rgb(255,69,0);
    color: white;
    font-weight: bold;
    width: 90%;
    font-size: 1rem;
  }
  }
      `}</style>
    </>
  );
};

export default RockClimbingChallenge;
