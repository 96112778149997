import React from 'react';
import Form from '../form';
import { useState, useEffect } from 'react';
const CustomButton = ({ onClick, buttonString, percent }) => (
  <button  onClick={onClick}  className="hicking-cta-button">
  BOOK YOUR SPOT
</button>
);
const RockClimbingChallenge = ({wholeDate}) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [wholeDates, setWholeDates] = useState(0);
    useEffect(() => {
      console.log(wholeDate, 'whole date4444')
      const currentDate = new Date();
      const differenceInMilliseconds = wholeDate - currentDate;
      let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      day = Math.max(day, 0);
      hour = Math.max(hour, 0);
      minute = Math.max(minute, 0);
      console.log(day, hour, minute)
      setDays(day)
      setHours(hour)
      setMinutes(minute)
      setWholeDates(wholeDate)
    }, [wholeDate]);
  
  
    useEffect(() => {
      const timerInterval = setInterval(() => {
        setMinutes(prevMinutes => {
          if (prevMinutes > 0) {
            return prevMinutes - 1;
          } else {
            setHours(prevHours => {
              if (prevHours > 0) {
                setMinutes(59);
                return prevHours - 1;
              } else {
                setDays(prevDays => {
                  if (prevDays > 0) {
                    setHours(23);
                    setMinutes(59);
                    return prevDays - 1;
                  } else {
                    clearInterval(timerInterval);
                    return 0;
                  }
                });
              }
            });
          }
        });
      }, 60000);
  
      return () => clearInterval(timerInterval);
    }, [days, hours, minutes]);
  return (
    <>
    <div className='div-cr'>
    <div className="rock-climbing-card1">
<br/>  <br/>  <br/>
<h2 className="rock-climbing-title">    
            HERE’S WHAT 
            YOU’LL GET
        </h2>
        <p className="rock-climbing-subtitle1">Structured Training</p>
        <p className="rock-climbing-description1">
        Professional coaching at AOS that prepares you specifically for the challenges of Hiking. This program includes everything training, Nutrition, In body measurements, What’s app group for ongoing support 
        </p>

        <p className="rock-climbing-subtitle1">Fun Hiking Events each week</p>
        <p className="rock-climbing-description1">
        Train at Egypt’s premier rock-climbing center with certified instructors.
        </p>


        <p className="rock-climbing-subtitle1">A Strong, Supportive Community</p>
        <p className="rock-climbing-description1">
        Meet like-minded individuals who are on the same journey, supporting, motivating each other, working towards a common goal.
        </p>

        <p className="rock-climbing-subtitle1">A Purpose to Train For</p>
        <p className="rock-climbing-description1">
        No more random gym sessions. Every workout brings you closer to the adventure at the end of the program.
        </p>


        <p className="rock-climbing-subtitle1">A Life-Changing Adventure</p>
        <p className="rock-climbing-description1">
        At the end of the program, we'll take you on a 2 day hiking trip to sinai or faiyoum that will challenge and inspire you
        </p>


        <div className="rock-climbing-countdown">
            <div className="rock-climbing-timer">
            <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
            </div>
          </div>

        <div style={{ textAlign: 'center' }}>
          <Form type={'hiking'}  buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR SPOT IN THIs CRAZY OFFER" onClick={toggleModal} />} />
        </div>
        <br/>
        <br/>
      </div>
    </div>


      <style>{`
      .div-cr {
                  background-color: #e2e4e9;
      }
          .rock-climbing-card1 {
            background-color: #e2e4e9;
            width: 100%;
    max-width: 850px;
    margin: 0 auto;
            }

  .rock-climbing-title {
    color: rgb(255,69,0);
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Futura XBlk BT', sans-serif;
    margin: -15px 0 0 0;
  }

  .rock-climbing-description1 {
    text-align: center;
    width: 90%;
    font-family: 'Siro-Bold', sans-serif;
    color: #374151;
    font-size: 1.5rem;
    margin-bottom: 2rem;
        margin-left: auto;
    margin-right: auto;
  }

  .rock-climbing-subtitle1 {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .rock-climbing-section {
    display: flex;
    margin-bottom: 2rem;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }

  .rock-climbing-section.reverse {
    flex-direction: row-reverse;
  }

  .rock-climbing-section-image,
  .rock-climbing-section-image-placeholder {
    width: 50%;
    max-width: 400px;
    height: 250px;
    border-radius: 0.5rem;
  }


  .rock-climbing-section-content,
  .rock-climbing-section-contentL {
    width: 50%;
    max-width: 400px;
  }

  .rock-climbing-section-content {
    margin-left: 3rem;
  }

  .rock-climbing-section-contentL {
    margin-right: 3rem;
    text-align: left;
  }

  .rock-climbing-section-title {
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .rock-climbing-section-text {
    color: #black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
    text-align: left;
  }

  .rock-climbing-countdown {
    font-family: 'Siro-Bold', sans-serif;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .rock-climbing-countdown p {
    font-family: 'Calibri', sans-serif;
  }

  .rock-climbing-section-text {
    color: black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
  }

  .rock-climbing-timer {
    font-size: 2rem;
        text-align: center;
    color: rgb(255,69,0);
  }



  .rock-climbing-timer span {
    margin: 0 5px;
  }

  .hicking-cta-button {
    background-color: rgb(255,69,0);
    color: white;
    font-weight: bold;

    padding: 0.75rem 2rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .hicking-cta-button:hover {
    background-color: rgb(255,69,0);
  }

  @media (max-width: 768px) {
    .rock-climbing-section,
    .rock-climbing-section.reverse {
      flex-direction: column;
      align-items: center;
    }

      .rock-climbing-description1 {
    font-size: 1rem;
  }

    .rock-climbing-section-image,
    .rock-climbing-section-image-placeholder,
    .rock-climbing-section-content,
    .rock-climbing-section-contentL {
      width: 90%;
      max-width: 350px;
      margin: 0 0 1rem 0;
    }
    .rock-climbing-section-contentL {
      text-align: center;
    }

    .rock-climbing-section-title {
      text-align: center;
    }
    .rock-climbing-title {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
        .rock-climbing-section-text {
    text-align: center;
  }

    .rock-climbing-subtitle1 {
    font-size: 1.25rem;
    }
    .hicking-cta-button {
    background-color: rgb(255,69,0);;
    color: white;
    font-weight: bold;
    width: 90%;
    font-size: 1rem;
  }
  }
      `}</style>
    </>
  );
};

export default RockClimbingChallenge;
