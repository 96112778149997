
import Header from "../components/header2";
import Header2 from "../components/hickingProgram/Header";
import Confused from "../components/confused";
import Header3 from "../components/hickingProgram/Header2";
import RockClimbingChallenge from "../components/hickingProgram/TextC";
import RockClimbingChallenge2 from "../components/hickingProgram/TextD";
import RockClimbingChallenge3 from "../components/hickingProgram/TextE";
import React, { useState, useEffect } from 'react';
const Hiking = () => {
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [enteredSpot, setEnteredSpot] = useState(0);
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);

  const [gymDay1, setGymDay1] = useState(0);
  const [gymHours1, setGymHours1] = useState(0);
  const [gymMinutes1, setGymMinutes1] = useState(0);
  const [wholeDate1, setWholeDate1] = useState(null);

  const [gymDay2, setGymDay2] = useState(0);
  const [gymHours2, setGymHours2] = useState(0);
  const [gymMinutes2, setGymMinutes2] = useState(0);
  const [wholeDate2, setWholeDate2] = useState(null);

  const [gymDay3, setGymDay3] = useState(0);
  const [gymHours3, setGymHours3] = useState(0);
  const [gymMinutes3, setGymMinutes3] = useState(0);
  const [wholeDate3, setWholeDate3] = useState(null);


  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl5, setYoutubeUrl5] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['hiking1']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['hiking2']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['hiking3']}`)
        setYoutubeUrl1(`${allDataResult['hikingyoutubeUrl5']}`)
        
        setGymDay(allDataResult['hikingDay'])
        setGymHours(allDataResult['hikingHour'])
        setGymMinutes(allDataResult['hikingMinute'])
        setWholeDate(allDataResult['hikingTimestamp'])

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [])


  return (
    <>
      <div className="div-cd">

        <Header />
        <div
          style={{
            backgroundColor:  "rgb(58, 56, 56)",  
          }}
    />
        <Header2 url={youtubeUrl1} wholeDate={wholeDate} />
        <Header3/>
        <RockClimbingChallenge image1={imageAssesmentUrl} image2={imageAllPeopleUrl} image3={imageJumpUrl}  wholeDate={wholeDate}/>
        <RockClimbingChallenge2 wholeDate={wholeDate}/>
        <br/>
        <br/>
        <RockClimbingChallenge3/>
        <Confused color={'rgb(255,69,0)'} type={'hiking'} />
      </div>

      <style jsx>{`
        .div-cd {
          box-sizing: border-box;
            text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-family: Inter, sans-serif;
  font-weight: 400;
  user-select: none;
  font-size: 62.5%;
          display: flex;
          flex-direction: column;
          background-color:'gray';
        }
        .videogroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height: 100vh; 
            margin-bottom: 20px;
            margin-top: 20px;
          }
          
          .videogroup iframe {
            width: 500px;
            height: 300px;
          }
          @media only screen and (max-width: 767px) {
            .videogroup iframe {
              width: 90%; 
              height: 300px; /* Preserve the aspect ratio */
            }
          }
      `}</style>
    </>
  );
};

export default Hiking;
